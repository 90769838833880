.signup-main-section {
  width: 100%;
  height: 100%;
  background-color: var(--main-bg-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    display: flex;
    gap: 48px;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 16px;
  }

  header {
    margin-bottom: 32px;
  }

  .inputs-container {
    margin-bottom: 40px;
    max-width: 340px;
  }

  .about-app-button {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    font-size: 1rem;
    color: var(--grey-30);

    svg {
      margin-right: 10px;
    }
  }

  #muffin-text {
    color: var(--main-yellow);
  }

  .form-container {
    width: 90%;
    max-width: 700px;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
  .signup-main-section {
    img {
      height: 120px;
      width: 120px;
    }

    .content {
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }

    .form-container {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 480px) {
  .signup-main-section {
    img {
      height: 50px;
      width: 50px;
    }

    .content {
      gap: 16px;
    }

    .header {
      margin-bottom: 8px;
    }

    .about-app-button {
      font-size: 0.8rem;
    }

    .about-app-button svg {
      font-size: 0.9rem;
      margin-right: 4px;
    }

    .inputs-container {
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 320px), only screen and (max-height: 620px) {
  .signup-main-section img {
    height: 0px;
    width: 0px;
  }
}
