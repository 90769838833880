.card-label-main-div {
  padding: 4px 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:disabled {
    opacity: 1;
  }

  p {
    color: white;
  }
}
