.checklist-section-main-div {
  width: 33%;
  height: 100%;
  background-color: #FEF8EA;
  padding: 18px 0px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  .section-title {
    font-weight: 500;
    margin-bottom: 8px;
    padding: 0px 12px;
  }

  .scrollable-div {
    padding: 0px 12px;
    overflow-y: auto;
    width: 100%;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
}

.add-card-bttn {
  width: 100%;
  padding: 8px 0px;
  border: 1px dashed #FCAA55;
  border-radius: 8px;

  p {
    color: var(--orange-66);
  }
}

@media only screen and (max-width: 1350px) {
  .checklist-section-main-div {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {
}
