.menu-main-div {
  width: 220px;
  height: 100%;
  background-color: var(--main-bg-color);
  border-right: 1px solid #EBD8AD;

  .notification-counter {
    width: 24px;
    height: 24px;
    background-color: var(--main-orange);
    color: white;
    border-radius: 50%;
    line-height: 24px;
    font-size: 0.8rem;
  }
}

.menu-main-div .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 40px 24px;
}

.menu-main-div .top-section {
  display: flex;
  flex-direction: column;
  align-items:center;
}

.item-menu-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0px;
  padding: 8px 16px;

  .item-menu-label {
    display: flex;
  }
}

.item-menu-main-container.active {
  background-color: var(--orange-66);
  border-radius: 12px;
}

.item-menu-main-container p{
  margin-left: 10px;
  font-weight: 500;
  color: var(--grey-10);
}

.item-menu-main-container.active p{
  color: white;
}

.menu-main-div .logout-button {
  display: flex;
  align-items: center;
  padding: 0px 16px;
}

.menu-main-div .logout-button p{
  color: var(--grey-10);
  margin-left: 10px;
}

#menu-logo{
  width: 140px;
  height: 52px;
  margin-bottom: 24px;
}


@media only screen and (max-width: 900px) {
  .menu-main-div {
    display: none;
  }
}
