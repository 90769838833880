@font-face {
  font-family: 'Poppins';
  src: url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url(./fonts/Poppins-Medium.ttf) format('truetype');
}

* {
  box-sizing: border-box;
  outline: none;
  overflow-wrap: break-word;
}

body {
  margin: 0;
  font-family: Poppins;
  color: var(--grey-10);

  --main-bg-color: #fdeecb;
  --main-yellow: #feb65e;
  --main-orange: #fcaa55;
  --grey-10: #241a0f;
  --grey-30: #584d41;
  --grey-60: #a3998f;
  --grey-85: #dbd9d6;
  --orange-66: #fcaa55;
  --red-70: #ff6666;
}

a {
  text-decoration: none;
}

button {
  font-family: Poppins;
  border: none;
  background-color: transparent;
  padding: 0px;

  &:active {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
  }
}

h2 {
  font-weight: 500 !important;
}

h4 {
  font-weight: 500 !important;
}

h6 {
  font-weight: 400 !important;
}

input[type='file'] {
  display: none;
}

/*CUSTOM SCROLLBAR*/
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.flex-row {
  display: flex;
}

.mobile-show {
  display: none !important;
}

#muffin-i {
  color: #734148;
  position: relative;
}
#muffin-i:before {
  content: "ı";
  position: absolute;
  color: var(--main-yellow);
}


@media only screen and (max-width: 768px) {
  .mobile-hide {
    display: none !important;
  }

  .mobile-show {
    display: flex !important;
  }
}

@media (hover: hover) {
  a:hover,
  button:hover:enabled {
    opacity: 0.7;
    cursor: pointer;
  }
}
