.type-popover-main-div {
  padding: 8px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 4px !important;
  max-height: 90%;
  overflow-y: auto;

  .type-div {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
    text-align: flex-start;

    p {
      color: var(--grey-10);
    }
  }

  .check-wrapper {
    display: flex;
    align-items: center;
    min-width: 16px;
  }

  .add-label-bttn {
    margin: 4px;
    display: flex;
    align-items: center;
    align-self: center;
  }
}
