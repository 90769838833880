.main-div-about-app-modal {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);

  width: 660px;
  max-height: 90%;
  box-sizing: border-box;
  background-color: white;

  padding: 32px 54px;
  border-radius: 8px;

  display: flex;
  align-items: center;
  flex-direction: column;

  h5 {
    margin: 8px 0px 24px;
  }

  .about-text {
    color: var(--grey-30);
    margin-bottom: 24px;
    overflow-y: auto;
  }
}

@media only screen and (min-width:320px) and (max-width: 480px) {
  .main-div-about-app-modal {
    width: 90%;
    padding: 16px;
  }
}

@media only screen and (min-width:481px) and (max-width: 768px) {
  .main-div-about-app-modal {
    width: 90%;
    padding: 16px;
  }
}

@media only screen and (min-width:769px) and (max-width: 1024px) {
  .main-div-about-app-modal p{
    font-size: 0.8rem;
  }
}

@media only screen and (min-width:1025px) and (max-width: 1200px) {
}
