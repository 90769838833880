.main-div-input {
  margin: 16px 0px;
  width: 100%;

  .MuiInputBase-input {
    background-color: white;
    border-radius: 16px;
    border: 0px solid #ced4da;
    font-size: 1rem;
    padding: 12px 12px;
    margin-top: 18px;
  }

  .MuiInputBase-multiline {
    padding: 0px;
  }

  &.darker .MuiInputBase-input {
    background-color: #fef8ea;
  }

  label {
    color: var(--grey-10);
    font-size: 1rem;
  }

  .add-emoji-div {
    position: absolute;
    bottom: 4px;
    right: 4px;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
  .main-div-input .MuiInputBase-input {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 480px) {
}
