.timer-main-section {
  flex: 0.43;
  min-width: 320px;
  height: 100%;
  background-color: var(--main-bg-color);
  padding: 40px 16px;

  .another-session-txt {
    margin-left: 4px;
  }
}

.timer-main-section .title {
  margin-bottom: 24px;
}

.timer-main-section h6 {
  font-weight: 500 !important;
  color: var(--grey-10);
}

.timer-main-section .form-div {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 24px;
}

.timer-main-section .session-div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.timer-main-section .submit-button {
  text-align: center;
}

@media only screen and (max-width: 1440px) {
  .timer-main-section .another-session-txt {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
  .timer-main-section {
    padding: 104px 16px 16px 16px;
  }
}

@media only screen and (max-width: 768px) {
  .timer-main-section {
    flex: 1;
    min-width: none;
  }
}

@media only screen and (max-width: 480px) {
}
