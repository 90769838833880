.choose-timer-main-div {
  padding: 0px 8px;
  flex: 1;
  width: 90%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: center;

  .flex-row {
    width: 100%;
    max-width: 380px;
    justify-content: space-between;
    gap: 8px;
  }

  .header-text {
    font-weight: 500;
    color: var(--grey-10);
    margin-bottom: 8%;
  }

  .time-container {
    margin: 16px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;

    .choose-time-bttn {
      color: var(--grey-10);
      border: 2px solid var(--grey-85);
      width: fit-content;
      margin: 4px 0px;

      p {
        font-weight: 500;
      }
    }

    .active {
      background-color: var(--orange-66);
      color: white;
      border: 2px solid var(--orange-66);
    }
  }

  .another-time-bttn {
    margin-top: 8px;

    display: flex;
    align-items: center;
    gap: 8px;
  }
}

/*BUTTONS*/
.choose-timer-main-div .time-container {
  .button-0 {
    border-radius: 10px;
    padding: 4px 40px;

    p {
      font-size: 1.2rem;
    }
  }

  .button-1 {
    border-radius: 12px;
    padding: 4px 44px;

    p {
      font-size: 1.4rem;
    }
  }

  .button-2 {
    border-radius: 14px;
    padding: 4px 48px;

    p {
      font-size: 1.6rem;
    }
  }

  .button-3 {
    border-radius: 14px;
    padding: 4px 52px;

    p {
      font-size: 1.8rem;
    }
  }

  .button-4 {
    border-radius: 14px;
    padding: 4px 56px;

    p {
      font-size: 2rem;
    }
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
  .room-main-section {
    flex-direction: column;
  }
}

@media only screen and (max-height: 750px) {
  .choose-timer-main-div .header-text {
    font-size: 1.5rem;
    margin: 0px 0px;
  }

  .choose-timer-main-div .time-container .button-0 {
    padding: 4px 34px;
  }

  .choose-timer-main-div .time-container .button-0 p {
    font-size: 0.8rem;
  }

  .choose-timer-main-div .time-container .button-1 {
    border-radius: 12px;
    padding: 4px 38px;
  }

  .choose-timer-main-div .time-container .button-1 p {
    font-size: 1rem;
  }

  .choose-timer-main-div .time-container .button-2 {
    border-radius: 14px;
    padding: 4px 42px;
  }

  .choose-timer-main-div .time-container .button-2 p {
    font-size: 1.2rem;
  }

  .choose-timer-main-div .time-container .button-3 {
    border-radius: 14px;
    padding: 4px 46px;
  }

  .choose-timer-main-div .time-container .button-3 p {
    font-size: 1.4rem;
  }

  .choose-timer-main-div .time-container .button-4 {
    border-radius: 14px;
    padding: 4px 50px;
  }

  .choose-timer-main-div .time-container .button-4 p {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width: 480px) {
  .choose-timer-main-div {
    padding: 0px 16px;
  }

  .choose-timer-main-div .header-text {
    margin: 16px 32px;
  }

  .choose-timer-main-div .flex-row {
    gap: 0px;
    width: 100%;
    justify-content: space-between;
  }

  .choose-timer-main-div .time-container {
    margin: 12px 0px;
  }

  .choose-timer-main-div .time-container .button-0 {
    padding: 4px 28px;
  }

  .choose-timer-main-div .time-container .button-0 p {
    font-size: 0.8rem;
  }

  .choose-timer-main-div .time-container .button-1 {
    border-radius: 12px;
    padding: 4px 32px;
  }

  .choose-timer-main-div .time-container .button-1 p {
    font-size: 1rem;
  }

  .choose-timer-main-div .time-container .button-2 {
    border-radius: 14px;
    padding: 4px 36px;
  }

  .choose-timer-main-div .time-container .button-2 p {
    font-size: 1.2rem;
  }

  .choose-timer-main-div .time-container .button-3 {
    border-radius: 14px;
    padding: 4px 40px;
  }

  .choose-timer-main-div .time-container .button-3 p {
    font-size: 1.4rem;
  }

  .choose-timer-main-div .time-container .button-4 {
    border-radius: 14px;
    padding: 4px 44px;
  }

  .choose-timer-main-div .time-container .button-4 p {
    font-size: 1.6rem;
  }

  .another-time-bttn p {
    display: none;
  }
}

@media only screen and (max-width: 340px), only screen and (max-height: 650px) {
  .choose-timer-main-div .header-text {
    display: none;
  }
}
