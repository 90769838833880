.join-room-main-section {
  width: 100%;
  height: 100%;
  background-color: var(--main-bg-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  .content {
    display: flex;
    gap: 48px;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 16px;
  }

  header {
    margin-bottom: 32px;
  }

  .inputs-container {
    margin-bottom: 40px;
    max-width: 340px;
  }

  .about-app-button {
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    font-size: 1rem;
    color: var(--grey-30);

    svg {
      margin-right: 10px;
    }
  }

  #muffin-text {
    color: var(--main-yellow);
  }

  .form-container {
    width: 90%;
    max-width: 700px;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
  .join-room-main-section {
    img {
      height: 120px;
      width: 120px;
    }

    .content {
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }

    .form-container {
      text-align: center;
    }
  }
}

@media only screen and (max-width: 480px), only screen and (max-height: 730px) {
  .join-room-main-section {
    img {
      height: 80px;
      width: 80px;
    }

    header {
      margin-bottom: 8px;
    }

    .content {
      gap: 12px;
    }

    .inputs-container {
      margin-bottom: 16px;
    }
  }
}
