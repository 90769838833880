.add-users-main-div {
  display: flex;
  align-items: center;
  padding: 8px 0px;

  .avatars-container {
    display: flex;
    margin-left: 8px;
    margin-right: -8px;

    div {
      margin-left: -8px;
    }
  }

  .add-member-bttn {
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--grey-10);
  }
}
