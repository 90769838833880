.menu-mobile-main-div {
  display: none;

  position: absolute;
  z-index: 100;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background-color: var(--main-bg-color);
  padding: 80px 16px 32px 16px;
  animation: fadeIn 0.3s;
  flex-direction: column;
}

.menu-mobile-main-div h5 {
  font-weight: 500;
}

.menu-mobile-main-div .menu-mobile-buttons {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.item-menu-mobile-main-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;
    gap: 12px;

    h5 {
      color: var(--grey-10);
    }
  }

  .right {
    display: flex;
    gap: 16px;

    .notification-counter {
      width: 24px;
      height: 24px;
      background-color: var(--main-orange);
      color: white;
      border-radius: 50%;
      line-height: 24px;
      font-size: 0.8rem;
    }
  }
}

.menu-mobile-main-div .logout-button {
  display: flex;
  align-items: center;
}

.menu-mobile-main-div .logout-button h6 {
  color: var(--grey-10);
  margin-left: 10px;
  font-weight: 500 !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 900px) {
  .menu-mobile-main-div {
    display: flex;
  }
}
