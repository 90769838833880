.checklist-card-details-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 24px;
  max-width: 70%;
  max-height: 90%;
  overflow-y: auto;

  background-color: white;
  border-radius: 8px;

  .header {
    .label {
      display: flex;
      justify-content: space-between;
    }

    .header-text {
      margin-top: 8px;
      margin-bottom: 4px;

      .MuiTypography-h6 {
        font-weight: 500 !important;
      }

      .MuiTypography-subtitle1 {
        color: var(--grey-60);

        button .MuiTypography-subtitle1{
          font-weight: 500 !important;
          color: var(--main-orange);
          margin-left: 4px;
        }
      }
    }
  }

  .section-title {
    color: var(--grey-10);
    font-weight: 500 !important;
  }

  .section-placeholder {
    color: var(--grey-30);
    white-space: pre-line;
  }

  .description-container {
    margin-top: 8px;
  }

  .MuiDivider-root {
    margin: 20px 0px;
  }

  .comment-main-div {
    padding-bottom: 8px;
    padding-top: 12px;
    border-bottom: 1px solid #a3998f88;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .author-div {
        display: flex;
        align-items: center;
        gap: 10px;

        h6 {
          color: var(--grey-10);
        }
      }
    }

    .message {
      color: var(--grey-30);
      white-space: pre-line;
      overflow-wrap: break-word;
    }
  }

  .additionally-container {
    margin-top: 16px;
  }

  .comment-input-div {
    margin-top: 16px;
    background-color: #FEF8EA;
    display: flex;
    align-items: center;
    gap: 8px;

    padding: 4px 4px 4px 12px;
    border-radius: 10px;
  }

  .MuiInput-root:before {
    border: 0 !important;
  }

  .MuiInput-root:after {
    border: 0 !important;
  }

  .MuiInput-input {
    padding: 0px;
  }
}

.additionally-card {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 4px;

  .user-name {
    font-weight: 500 !important;
  }

  .subtitle {
    color: var(--grey-60);
  }
}

//HEIGHT MEDIA QUERIES
@media only screen and (max-height: 700px) {
}

//WIDTH MEDIA QUERIES
@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 768px) {
  .checklist-card-details-div {
    max-width: none;
    max-height: none;
    width: 100vw;
    height: calc(100% - 104px);
    position: absolute;
    top: 104px;
    left: 0%;
    transform: translate(0%, 0%);

    border-radius: 0px;
  }
}

@media only screen and (max-width: 480px) {
}
