.navbar-main-container {
  display: none;

  position: fixed;
  z-index: 999;
  width: 100%;
}

.navbar-main-container .top-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
}

.navbar-main-container .bottom-section {
  position: relative;
  width: 100%;
  background-color: var(--main-bg-color);
  padding: 4px 16px;
}

.navbar-main-container .bottom-section p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
}

.menu-badge {
  .BaseBadge-badge {
    margin-top: 4px;
  }
}

@media only screen and (max-width: 900px) {
  .navbar-main-container {
    display: flex;
    flex-direction: column;
  }
}
