.add-another-session-modal {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%);

  width: 90%;
  max-width: 500px;
  background-color: var(--main-bg-color);

  padding: 20px 40px;
  border-radius: 8px;
}

@media only screen and (max-width: 480px) {
  .add-another-session-modal {
    padding: 16px 24px;
  }
}
