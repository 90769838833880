.home-main-div {
  flex: 1;
  padding: 0px 8px;
  background-color: white;
  min-width: 350px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.pause {
    background-color: #ff7b7b;
  }
}

/*PAUSE CSS*/
.home-main-div.pause{
  .main-timer-div {
    color: white;
  }

  .room-name-container * {
    color: white;
  }

  #timer-subtitle {
    color: white;
  }

  .button-container .button-main-div {
    background: #ffffff;

    p {
      color: var(--orange-66);
    }
  }

  .button-container .button-main-div.transparent {
    background: transparent;
    border-color: white;

    p {
      color: white;
    }
  }

  .invite-friends-text {
    color: white;
  }

  .take-break-bttn {
    color: white;
  }
} 

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {
}
