.room-main-section {
  height: 100%;
  width: 100%;
  display: flex;
}

.room-main-section .room-tab-main-container{
  flex: 1;
  background-color: white;
}

.tab-wrapper {
  flex: 1;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content:center;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
  .room-main-section {
    flex-direction: column;
  }
}

@media only screen and (max-width: 480px) {
}

