.checklist-main-div {
  width: 60vw;
  height: 100%;
  background-color: var(--main-bg-color);
  padding: 40px 16px;

  display: flex;
  justify-content: space-between;

  .swiper-pagination-bullet-active {
    background-color: var(--main-orange);
    width: 10px;
    height: 10px;
  }
}

@media only screen and (max-width: 1350px) {
  .checklist-main-div {
    width: 35vw;
  }
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
  .checklist-main-div {
    padding: 64px 16px 16px;
  }
}

@media only screen and (max-width: 768px) {
  .checklist-main-div {
    padding: 104px 12px 12px 12px;
    width: 100vw;
  }
}

@media only screen and (max-width: 480px) {
}
