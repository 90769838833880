.create-card-main-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 24px;
  width: 500px;
  width: 90%;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;

  background-color: white;
  border-radius: 8px;

  .header {
    font-weight: 500 !important;
    color: var(--grey-60);
    margin-bottom: 16px;

    button .MuiTypography-h6{
      font-weight: 500 !important;
      margin-left: 4px;
      color: #b97750;
    }
  }

  .bttn-container {
    margin-top: 32px;
    margin-bottom: 16px;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 768px) {
  .create-card-main-div {
    max-width: none;
    max-height: none;
    width: 100vw;
    height: calc(100% - 104px);
    position: absolute;
    top: 104px;
    left: 0%;
    transform: translate(0%, 0%);

    border-radius: 0px;
  }
}

@media only screen and (max-width: 480px) {
}
