.card-main-div {
  background-color: white;
  padding: 12px 8px 16px 8px;
  border-radius: 8px;
  margin-top: 8px;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .more-options-bttn {
      display: flex;
      align-items: center;

      &:hover {
        opacity: 1 !important;

        .icon {
          display: none;
        }

        .hover-icon {
          display: inline;
        }
      }

      .icon {
        display: inline;
      }

      .hover-icon {
        display: none;
      }
    }
  }

  .card-details-bttn {
    width: 100%;
    text-align: left;
  }

  .card-title {
    font-weight: 500;
  }

  .card-description {
    color: var(--grey-30);
    margin-top: 2px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;

    .comments-div {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 12px 0px;

      h6 {
        color: var(--grey-30);
      }
    }

    .users-div {
      display: flex;
      align-items: center;
      margin-left: 8px;

      div {
        margin-left: -8px;
      }
    }
  }
}

.MuiPaper-root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 8px !important;

  .edit {
  }

  .delete {
    color: var(--red-70);
  }
}
