.about-tab {
  overflow: auto;
  flex: 0.43;
  min-width: 320px;
  height: 100%;
  background-color: var(--main-bg-color);
  padding: 40px 16px;

  .content {
    margin: 16px 0px;
    color: var(--grey-30);
  }

  h5 {
    font-weight: 500;
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
  .about-tab {
    padding: 64px 16px;
  }
}

@media only screen and (max-width: 768px) {
  .about-tab {
    flex: 1;
    min-width: auto;
    padding: 104px 16px 0px 16px;
  }
}

@media only screen and (max-width: 480px) {
}
