.add-label-main-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 8px;

  .header {
    position: relative;
    border-bottom: 1px solid #DBD9D6;;
    text-align: center;
    padding: 12px 0px;

    h6 {
      font-weight: 500 !important;
    }

    .back-bttn {
      position: absolute;
      transform: translate(0%, -50%);
      top: 50%;
      left: 24px;

      h6 {
        color: var(--grey-10);
      }
    }

    .close-bttn {
      position: absolute;
      transform: translate(0%, -50%);
      top: 50%;
      right: 24px;
      display: flex;
    }
  }

  .content {
    padding: 20px 24px;

    .labels-container {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-height: 260px;
      overflow-y: auto;
    }
  }

  .add-label-bttn {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 20px;

    h6 {
      color: var(--grey-10);
    }
  }

  .add-label-header-text {
    color: var(--grey-10);
  }

  .add-bttn {
    margin-top: 24px;
    margin-bottom: 8px;
    text-align: center;
  }

  .choose-color {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .text {
      color: var(--grey-10);
    }
  }

  .create-new-label-bttn {
    text-align: center;
    margin-top: 36px;
    margin-bottom: 24px;
  }
} 
