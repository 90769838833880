.profile-main-section {
  flex: 0.43;
  min-width: 320px;
  height: 100%;
  background-color: var(--main-bg-color);
  padding: 40px 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-main-section .input-container {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
  .profile-main-section {
    padding: 64px 16px;
  }
}

@media only screen and (max-width: 768px) {
  .profile-main-section {
    padding: 104px 16px 16px 16px;
    flex: 1;
    min-width: none;
  }
}

@media only screen and (max-width: 480px) {
}
