.room-details-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
}

.room-details-main-div .users-container {
  display: flex;
  margin-bottom: 8px;
}

.room-details-main-div .avatars-container{
  display: flex;
}

.room-details-main-div .avatars-container div{
  margin-left: -8px;
}

.room-details-main-div .users-container button{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: -8px;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
}

.room-details-main-div .users-container .add-user {
  border: 1px solid var(--main-orange);
  background-color: white;
}

.room-details-main-div .users-container .add-user p{
  font-size: 1.5rem;
  color: var(--main-orange);
}

.room-details-main-div .room-name-container {
  display: flex;
}

.room-details-main-div .room-name-container p{
  color: var(--grey-30);
  margin-right: 8px;
}

.room-details-main-div .room-name-container img{
  margin: 0px 4px;
}

.add-user-tooltip {
  padding: 8px 16px !important;
  background-color: white !important;
  box-shadow: 0px 1px 4px #D4D4D4;
  text-align: center;
}

.add-user-tooltip .title{
  font-size: 0.7rem;
  font-weight: 400;
  margin: 0px;
  color: var(--grey-30);
  margin-bottom: 4px;
}

.add-user-tooltip .subtitle{
  font-size: 0.8rem;
  font-weight: 400;
  margin:0px;
  color: var(--grey-10);
}

.share-popover-main-div {
  padding: 4px 0px;
  display: flex;
  gap: 8px;

  button {
    display: flex;
  }

  .copy-link-button {
    width: 32px;
    height: 32px;
    background-color: #c5c5c5;
    border-radius: 50%;

    svg {
      margin: auto;
      font-size: 16px;
      color: white;
    }
  }
}

.invite-friends-text {
  color: var(--grey-30);
  font-size: 0.9rem !important;
}


@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 480px) {
  .room-details-main-div {
    margin-bottom: 16px;
  }
}

@media only screen and (max-height: 740px) {
  .room-details-main-div {
    margin-bottom: 16px;
  }
}

