.checkbox-div {
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    color: var(--grey-60);
    font-size: 0.78rem;
  }

  .MuiCheckbox-root {
    color: var(--grey-60);
  }
}
