.avatar-div{
  position: relative;
  border-radius: 50%;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  .user-picture{
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .edit-button{
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex;
    align-items: flex-end;

    &:active {
      opacity: 0.7;
    }
  }
}

@media (hover: hover) {
  .avatar-div{
    .edit-button{
      &:hover {
        opacity: 0.7;
        cursor: pointer;
      }
    }
  }
}
