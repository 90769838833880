.chat-tab {
  flex: 0.43;
  min-width: 320px;
  height: 100%;
  background-color: var(--main-bg-color);
  padding: 40px 16px;
}

.chat-main-container {
  padding: 24px 0px;
  height: 100%;
  width: 100%;
  background-color: #fef8ea;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .chat-header {
    margin: 0px 16px;

    .chat-header-txt {
      text-transform: capitalize;
    }
  }

  h5 {
    font-weight: 500;
  }
}

.chat-messages-container {
  flex: 1;
  padding: 0px 16px;
  overflow-y: auto;
}

.chat-input-div {
  margin: 0px 16px;
  display: flex;
  align-items: center;

  background-color: white;
  padding: 4px;
  border-radius: 10px;
}

.chat-input-div img {
  margin: 0px 8px;
  vertical-align: middle;
}

.chat-input-div .MuiInput-root:before {
  border: 0 !important;
}

.chat-input-div .chat-input-field {
  padding: 2px 0px;
}

/*CHAT MESSAGE*/
.chat-message-main-div {
  display: flex;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 16px;
}

.chat-message-main-div .messages-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.chat-message-content {
  width: fit-content;
  min-width: 180px;
  overflow-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
  background-color: white;
  padding: 4px 16px;
  border-radius: 10px;
  box-shadow: 0px 1px 4px #d4d4d4;
}

.chat-message-content .chat-message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-message-content .chat-message-header .MuiTypography-subtitle1 {
  font-weight: 500 !important;
  color: var(--orange-66);
}

.chat-message-content .chat-message-header .MuiTypography-subtitle2 {
  color: var(--grey-60);
}

.chat-message-content p {
  font-size: 0.9rem;
}

/*CONDENSED MESSAGE*/
.chat-message-content.condensed {
  display: flex;
  justify-content: space-between;
  gap: 4px;
}

.chat-message-content.condensed p {
  flex: 1;
}

.chat-message-content.condensed .MuiTypography-subtitle2 {
  color: var(--grey-60);
}

/*MESSAGE SEND FROM THE CURRENT USER*/
.my-message {
  justify-content: flex-end;
}

.my-message .messages-container {
  align-items: flex-end;
}

.my-message .chat-message-content {
  background-color: #fdf1d5;
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 900px) {
  .chat-tab {
    padding: 64px 16px 16px;
  }
}

@media only screen and (max-width: 768px) {
  .chat-tab {
    flex: 1;
    min-width: none;
    padding: 104px 0px 0px 0px;
  }

  .chat-main-container {
    border-radius: 0px;
  }
}

@media only screen and (max-width: 480px) {
}
