.main-timer-div {
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.work-section-div {
  display: flex;
  flex-direction: column;
  align-items: center;

  #muffin-ilustration {
    margin-top: 32px;
    margin-bottom: 16px;
  }
}

.main-timer-div .timer-value {
  margin-top: 48px;
  margin-bottom: 24px;
}

.main-timer-div .button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .row {
    display: flex;
    gap: 8px;
    min-width: 200px;
  }
}

.break-section-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-cards-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.game-card-div {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #dbd9d6;
  border-radius: 12px;
  padding: 6px 16px;

  button {
    width: fit-content;
    background-color: var(--orange-66);
    color: white;
    padding: 0px 12px;
    border-radius: 8px;
  }

  div {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h6 {
      font-weight: 500 !important;
    }
  }

  #game-card-logo {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
}

.game-modal-main-div {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;

  padding: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 8px;

  .game-modal-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  iframe {
    border-radius: 8px;

    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }

  &.pause {
    background-color: #ff7b7b;

    .timer-value,
    svg {
      color: white;
    }
  }

  &.game-2048 .aspect-ratio {
    height: calc(80 * var(--vh));
    width: 80vw;

    max-height: 800px;
    max-width: 700px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &.game-flappy-bird .aspect-ratio {
    height: calc(80 * var(--vh));
    width: 80vw;

    max-height: 800px;
    max-width: 500px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &.game-tetris .aspect-ratio {
    height: calc(80 * var(--vh));
    width: 80vw;

    max-height: 800px;
    max-width: 400px;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.take-break-bttn {
  color: var(--orange-66);
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
}

@media only screen and (max-width: 768px) {
  .game-modal-main-div {
  }
}

@media only screen and (max-width: 480px) {
  .main-timer-div .timer-value {
    margin-top: 32px;
  }

  .work-section-div #muffin-ilustration {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-height: 740px) {
  .work-section-div #muffin-ilustration {
    width: 200px;
    height: 200px;
  }

  .main-timer-div .timer-value {
    margin-top: 24px;
    margin-bottom: 12px;
  }
}

@media only screen and (max-height: 580px) {
  .work-section-div #muffin-ilustration {
    display: none;
  }

  .game-cards-container {
    display: none;
  }

  #timer-subtitle {
    display: none;
  }
}
