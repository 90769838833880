.button-main-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 0px;
  background-color: var(--main-orange);
  border-radius: 12px;
  margin: 0px auto;

  p {
    color: white;
    font-size: 1rem;
  }

  svg {
    color: white;
  }

  &.small {
    padding: 4px 16px;
    width: auto !important;
    border-radius: 10px;
  }

  &.transparent {
    background-color: transparent;
    border: 1px solid var(--orange-66);

    p {
      color: var(--main-orange);
    }

    svg {
      color: var(--main-orange);
    }
  }

  &.not-active {
    background-color: white;

    p {
      color: var(--grey-10);
    }
  }
}

@media only screen and (max-width: 480px) {
  .button-main-div {
    width: 100% !important;
  }
}
